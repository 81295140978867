import 'ress';
import { throttle } from 'throttle-debounce';
import $ from 'jquery';
import 'core-js/stable';
import '../scss/style.scss';

$.fn.delayAddClass = function (className, delay) {
  return $(this)
    .delay(delay)
    .queue(function (next) {
      $(this).addClass(className);
      next();
    });
};
$.fn.delayRemoveClass = function (className, delay) {
  return $(this)
    .delay(delay)
    .queue(function (next) {
      $(this).removeClass(className);
      next();
    });
};

$(() => {
  // const headNav = $("header");
  // const topnavfixed = $(window).height() - 100;


  // $(window).on('load scroll', function () {
  //   if ($(this).scrollTop() > topnavfixed && headNav.hasClass('fixed') === false) {
  //     headNav.addClass('fixed');
  //   } else if ($(this).scrollTop() < 100 && headNav.hasClass('fixed') === true) {
  //     headNav.removeClass('fixed');
  //   }
  // });
  /**
   * ナビゲーションのa11y対応
   */
  const $mobileNavTrigger = $(".js-mobile-nav-btn");
  const $mobileNavContents = $(".js-mobile-nav");

  $mobileNavTrigger.on("click", () => {
    $("body").toggleClass("is-locked");
    const $mobileNavAttr = $mobileNavTrigger.attr("aria-expanded");

    if ($mobileNavAttr === "true") {
      $mobileNavTrigger.attr("aria-expanded", "false");
      $mobileNavContents
        .removeClass("is-opened is-active")
        .delayRemoveClass("is-opened", 1)
        .delayRemoveClass("is-active", 100);
    } else {
      $mobileNavTrigger.attr("aria-expanded", "true");
      $mobileNavContents
        .addClass("is-active")
        .delayAddClass("is-opened", 100);
    }

    $mobileNavContents
      .find("[href*='#']")
      .on("click", () => {
        $mobileNavTrigger.attr("aria-expanded", "false");
        $mobileNavContents
          .removeClass("is-opened is-active")
          .delayRemoveClass("is-opened", 1)
          .delayRemoveClass("is-active", 100);
        $("body").removeClass("is-locked");
      });
  });
  // アニメーションのトリガー
  const scrollAddClass = () => {
    const scrollElms = document.querySelectorAll('[class*="js_fade"]'); // 表示させる要素
    const windowHeight = window.innerHeight; // ウインドウの高さ
    const scrollValue = window.pageYOffset; // スクロール量
    const windowOffset = 0; // 表示させる時のオフセット
    if (scrollValue > 100) {
      document.body.classList.add('is_scrolled');
    } else {
      document.body.classList.remove('is_scrolled');
    }
    for (let i = 0; i < scrollElms.length; i += 1) {
      // 取得した要素のtop値の取得 + スクロール量
      const scrollTop = scrollElms[i].getBoundingClientRect().top + scrollValue;
      if (scrollValue > scrollTop - windowHeight + windowOffset) {
        scrollElms[i].classList.add('is_appeared');
      }
    }
  };

  window.addEventListener('load', scrollAddClass);
  window.addEventListener('scroll', throttle(100, () => {
    scrollAddClass();
  }));
  window.addEventListener('resize', throttle(100, () => {
    scrollAddClass();
  }));

  // フェードイン 帯
  const fadeInslideAddClass = () => {
    const scrollElms = document.querySelectorAll('[class*="js_fadeSlide"]'); // 表示させる要素
    const windowHeight = window.innerHeight; // ウインドウの高さ
    const scrollValue = window.pageYOffset; // スクロール量
    const windowOffset = 0; // 表示させる時のオフセット

    if (scrollValue > 100) {
      document.body.classList.add('is_scrolled');
    } else {
      document.body.classList.remove('is_scrolled');
    }
    for (let i = 0; i < scrollElms.length; i += 1) {
      // 取得した要素のtop値の取得 + スクロール量
      const scrollTop = scrollElms[i].getBoundingClientRect().top + scrollValue;
      if (scrollValue > scrollTop - windowHeight + windowOffset) {
        scrollElms[i].classList.add('is_active');
      }
    }
  };

  window.addEventListener('load', fadeInslideAddClass);
  window.addEventListener('scroll', throttle(300, () => {
    fadeInslideAddClass();
  }));
  window.addEventListener('resize', throttle(300, () => {
    fadeInslideAddClass();
  }));
});